import { Fragment, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { pageActions } from '../../slices/pageSlice';
import uuid from 'react-uuid';
import axios from 'axios';
import EditUserExp from './EditUserExp';

const UserCVEdit = ({ cv, setEditStatus, validUser }) => {
  const { user } = useSelector((state) => state.page);
  const { changeCV, setIsLoading } = pageActions;
  const dispatch = useDispatch();

  const [editedCV] = useState({ ...cv });
  const { Position, Contact_information, Experience, Skills, Location } =
    editedCV;

  const [workExp, setWorkExp] = useState(
    Experience
      ? [...Experience].map((exp) => JSON.parse(JSON.stringify(exp)))
      : []
  );
  const [position, setPosition] = useState(Position);
  const [contactNum, setContactNum] = useState(Contact_information);
  const [skills, setSkills] = useState(Skills || []);
  const [skillInput, setSkillInput] = useState('');

  const addSkill = () => {
    setSkills((prev) => [...prev, skillInput]);
    setSkillInput('');
  };

  const updateCV = async () => {
    const cvExpJSONArr = (cv['Experience'] ? cv['Experience'] : []).map((exp) =>
      JSON.stringify(exp)
    );

    editedCV['Experience'].forEach((exp) => {
      const editedExpJSON = JSON.stringify(exp);
      if (cvExpJSONArr.indexOf(editedExpJSON) == -1) {
        exp['Confirmed'] = false;
        exp['edited'] = true;
      } else {
        exp['edited'] = false;
      }
    });

    dispatch(setIsLoading(true));

    const data = JSON.stringify({
      Location: cv['Location'],
      Skills: skills,
      Position: position,
      Contact_information: contactNum,
      Experience: editedCV['Experience'],
      user,
    });

    const token = sessionStorage.getItem('USER_ACCESS_TOKEN');

    await axios
      .post('https://server.a-rogovsky1276.workers.dev/update-cv', data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => res.data)
      .then((res) => {
        const { Experience } = res;
        editedCV['Experience'] = Experience;
      });

    dispatch(changeCV(editedCV));
    dispatch(setIsLoading(false));
  };

  useEffect(() => {
    editedCV['Skills'] = skills;
    editedCV['Contact_information'] = contactNum;
    editedCV['Position'] = position;
    editedCV['Experience'] = workExp;
  }, [skills, contactNum, position, workExp]);

  return (
    <li className="border-[1px] rounded-lg flex flex-wrap relative bg-white">
      <form
        className="flex flex-wrap"
        onSubmit={(e) => {
          e.preventDefault();

          setEditStatus(false);
          updateCV();
        }}
      >
        <div className="px-4 py-6">
          <div className="flex gap-[15px]">
            <div className="font-medium">Position:</div>
            <input
              className="px-2 rounded w-[150px] outline outline-[1px] outline-[rgb(0,0,0,0.2)]"
              type={'text'}
              value={position}
              onChange={(e) => {
                setPosition(e.target.value);
              }}
            />
          </div>
          <div className="flex gap-[15px] mt-[15px]">
            <div className="font-medium">Contact information:</div>
            <input
              className="px-2 rounded w-[150px] outline outline-[1px] outline-[rgb(0,0,0,0.2)]"
              type={'text'}
              value={contactNum}
              onChange={(e) => {
                setContactNum(e.target.value);
              }}
            />
          </div>
          <div className="mt-[15px]">
            <div className="font-medium flex w-full justify-between items-center">
              Experiences:
              <button
                type="button"
                className="border px-2 rounded flex items-center h-[34px] w-[34px]"
                onClick={() => {
                  setWorkExp((prev) => {
                    return [
                      ...prev,
                      {
                        Company: {
                          Company_name: '',
                          Contact_information: '',
                          Cooperate: false,
                          Domain: '',
                        },
                        Confirmed: false,
                        Months: 0,
                        Location: uuid(),
                      },
                    ];
                  });
                }}
              >
                <i className="material-icons text-[16px]">add</i>
              </button>
            </div>
            <ul className="w-fit flex flex-col gap-4">
              {workExp.length != 0 &&
                workExp.map((exp) => {
                  return (
                    <EditUserExp
                      key={exp['Location']}
                      expFromCV={exp}
                      setWorkExp={setWorkExp}
                      cvLocation={Location}
                    />
                  );
                })}
            </ul>
          </div>
        </div>
        <div className="flex flex-1 flex-col border-l px-4 py-6">
          <div className="mb-[10px] font-medium">Skills:</div>
          <div className="flex flex-col flex-1 justify-between gap-2">
            <div className="flex flex-wrap gap-2">
              {skills.map((el) => {
                return (
                  <div
                    className="pl-3 pr-1 py-1 rounded bg-slate-100 border-slate-500  border text-sm font-medium flex items-center h-fit gap-2.5 select-none"
                    key={uuid()}
                  >
                    {el}
                    <i
                      className="material-icons text-[16px] cursor-pointer hover:bg-red-500 rounded"
                      onClick={() => {
                        setSkills((prev) =>
                          prev.filter((skill) => skill != el)
                        );
                      }}
                    >
                      close
                    </i>
                  </div>
                );
              })}
            </div>
            <div className="flex gap-2">
              <input
                className="border px-2 py-1  rounded"
                type={'text'}
                value={skillInput}
                onChange={(e) => {
                  setSkillInput(e.target.value);
                }}
                placeholder={'Enter skill name'}
              />
              <button
                type="button"
                className="border px-2 rounded flex items-center"
                onClick={() => {
                  if (skillInput != '') {
                    addSkill();
                  }
                }}
              >
                <i className="material-icons text-[16px]">add</i>
              </button>
            </div>
          </div>
        </div>
        <button className="absolute top-[10px] right-[10px] font-medium border py-1 px-3 rounded">
          Save
        </button>
      </form>
    </li>
  );
};

export default UserCVEdit;
