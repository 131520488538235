import React, { Fragment, useState } from 'react';
import UserCVDisplay from './UserCVDisplay';
import UserCVEdit from './UserCVEdit';

const UserCV = ({ cv, validUser }) => {
  const [editStatus, setEditStatus] = useState(false);

  return (
    <>
      {editStatus ? (
        <UserCVEdit
          cv={cv}
          setEditStatus={setEditStatus}
          validUser={validUser}
        />
      ) : (
        <UserCVDisplay cv={cv} setEditStatus={setEditStatus} />
      )}
    </>
  );
};

export default React.memo(UserCV);
