import { useAuth0 } from '@auth0/auth0-react';

import { Link } from 'react-router-dom';

import Hero from './Hero';
import EmployedInfo from './EmployedInfo';
import Employment from './Employment';
import ChoiseOfCountry from './ChoiseOfCountry';
import WhatProvide from './WhatProvide';
import TipsForDeal from './TipsForDeal';
import FAQ from './FAQ';
import FillCVBanner from './FillCVBanner';
import VacancyShowcase from './VacancyShowcase';

const MainPage = () => {
  const { user } = useAuth0();
  return (
    <>
      <div className="w-full flex flex-col">
        <Hero />
        <VacancyShowcase />
        <EmployedInfo />
        <Employment />
        <ChoiseOfCountry />
        <WhatProvide />
        <TipsForDeal />
        <FAQ />
        <FillCVBanner />
        {/* <div className="py-5 text-center text-[28px] font-medium">
          Vacancies {user && `for you, ${user.name}`}
        </div>
        <div className="flex justify-center mt-16">
          <Link to="/vacancies">
            <button className="py-2 px-3 bg-red-500 hover:bg-red-600 transition duration-300 text-white rounded">
              Browse all vacancies
            </button>
          </Link>
        </div> */}
      </div>
    </>
  );
};

export default MainPage;
