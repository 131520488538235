import Container from './Container';
import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect, useState } from 'react';
import UserHeader from './UserHeader';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

const Header = () => {
  const { loginWithRedirect, logout, ...auth0Values } = useAuth0();
  const { user } = useSelector((state) => state.page);

  return (
    <>
      <header className="h-16 w-full border-b ">
        <Container>
          <nav className="flex w-full px-6 h-full justify-between items-center">
            <div className="flex gap-[20px] items-center">
              <Link to="/">
                <div className="text-[30px] font-medium">Joblist</div>
              </Link>
            </div>
            <div className="flex gap-5 items-center">
              {/* <button
                className="px-5 h-10 rounded-lg  border-yellow-400 bg-yellow-200 font-medium border text-[14px]"
                onClick={() => {
                  if (auth0Values.user) {
                  } else {
                    loginWithRedirect();
                  }
                }}
              >
                Premium
              </button> */}
              {!user && (
                <button
                  className="px-8 h-10 border text-[14px] border-[rgb(0,0,0,0.15)] hover:shadow-lg transition duration-300 text-black rounded-lg font-medium"
                  onClick={() => loginWithRedirect()}
                >
                  Login
                </button>
              )}

              {user && (
                <>
                  <UserHeader />
                </>
              )}
            </div>
          </nav>
        </Container>
      </header>
    </>
  );
};

export default Header;
