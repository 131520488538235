import uuid from 'react-uuid';

const SkeletonBlocks = ({ count }) => {
  const generateBlocks = () => {
    let blocks = [];
    for (let i = 0; i < count; i++) {
      blocks.push(
        <div key={uuid()} className=" bg-white skeleton rounded-lg h-[125px]" />
      );
    }
    return blocks;
  };
  return <>{generateBlocks()}</>;
};

export default SkeletonBlocks;
