import { Fragment } from 'react';
import uuid from 'react-uuid';

const UserCVDisplay = ({ cv, setEditStatus }) => {
  const { Position, Contact_information, Experience, Skills } = cv;
  return (
    <>
      <li className="border-[1px] rounded-lg flex flex-wrap relative bg-white">
        <button
          onClick={() => setEditStatus(true)}
          className="absolute top-[10px] right-[10px] flex items-center gap-2 border p-2 cursor-pointer select-none rounded hover:bg-[rgb(0,0,0,0.025)]"
        >
          <i className="material-icons text-[16px]">edit</i>
        </button>

        <div className="px-4 py-6 w-[450px]">
          <div className="flex gap-[15px]">
            <div className="font-medium">Position:</div>
            <div className="px-2">{Position} </div>
          </div>
          <div className="flex gap-[15px] mt-[15px]">
            <div className="font-medium">Contact information:</div>
            <div className="px-2">{Contact_information}</div>
          </div>
          <div className="mt-[15px]">
            <div className="font-medium">Experiences: </div>
            <ul>
              {Experience &&
                Experience.map((company) => {
                  return (
                    <Fragment key={uuid()}>
                      <li
                        className={`flex gap-[30px] px-5 py-3 mt-2 border-[1px] rounded w-full ${
                          company['Confirmed']
                            ? 'border-green-500 bg-green-100'
                            : 'border-red-500 bg-red-100'
                        }`}
                      >
                        <div className="flex gap-[15px]">
                          <div className="font-medium">Company name:</div>
                          <div>{company['Company']['Company_name']}</div>
                        </div>
                        <div className="flex gap-[15px]">
                          <div className="font-medium">Experience:</div>
                          <div>{company['Months']}</div>
                        </div>
                      </li>
                    </Fragment>
                  );
                })}
            </ul>
          </div>
        </div>
        <div className="flex flex-1 flex-col border-l px-4 py-6">
          <div className="mb-[10px] font-medium">Skills:</div>
          <div className="flex flex-wrap gap-2">
            {(Skills || []).map((el) => {
              return (
                <div
                  className="px-3 py-1 rounded bg-slate-100 border-slate-500  border text-sm font-medium flex items-center h-fit gap-2.5 select-none"
                  key={uuid()}
                >
                  {el}
                </div>
              );
            })}
          </div>
        </div>
      </li>
    </>
  );
};

export default UserCVDisplay;
