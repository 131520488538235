import React from 'react';
import { Link } from 'react-router-dom';

const FillCVBanner = () => {
  return (
    <>
      <div className="w-full py-[90px]">
        <div className="w-full max-w-[1200px] mx-auto px-[25px]">
          <div className="flex flex-col max-w-[600px] w-full mx-auto px-[20px]">
            <div className="text-[28px] font-[500] text-center">
              Fill out your resume and get your dream job!
            </div>
            <div className="flex justify-center">
              <div className="w-[300px] h-[200px] mt-[60px] flex flex-col items-center justify-between bg-white rounded-[30px] border-[1px] px-[30px] py-[20px]">
                <h3 className="text-[21px] font-[500]">Leave a CV</h3>
                <Link to="/cv">
                  <button className="px-[20px] py-[8px] max-w-[215px] font-[500] w-full text-white rounded-lg text-[20px] bg-blue-400">
                    Fill out
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FillCVBanner;
