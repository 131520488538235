import { useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import Container from './Container';
import CVForm from './CV/CVForm';
import VacanciesList from './Vacancy/VacanciesList';
import UserCVPage from './UserCV/UserCVPage';
import VacancyPage from './Vacancy/VacancyPage';
import MainPage from './Pages/MainPage/MainPage';
import CVUpload from './CV/CVUpload';
import CVOptions from './CV/CVOptions';
import ThankPage from './Pages/ThankPage';

const Main = ({ validUser }) => {
  const { user } = useSelector((state) => state.page);

  return (
    <>
      <main className="w-full min-h-[calc(100vh-64px)] bg-[#fafafa]">
        <Container>
          <Routes>
            <Route path="/" element={<MainPage />} />
            {user && (
              <>
                <Route
                  path="/your-cv"
                  element={
                    user['CV'].length == 0 ? (
                      <CVForm validUser={validUser} />
                    ) : (
                      <UserCVPage validUser={validUser} />
                    )
                  }
                />
                <Route
                  path="/create-cv"
                  element={<CVForm validUser={validUser} />}
                />
              </>
            )}
            <Route path="/cv" element={<CVOptions />} />
            <Route path="/cv/upload" element={<CVUpload />} />
            <Route path="/thank-page" element={<ThankPage />} />
            <Route path="/vacancies" element={<VacanciesList />} />
            <Route path="/vacancies/:id" element={<VacancyPage />} />
            <Route path="*" element={<MainPage />} />
          </Routes>
        </Container>
      </main>
    </>
  );
};

export default Main;
