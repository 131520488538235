import { Fragment } from 'react';
import { formatTime } from '../../helpers';

import uuid from 'react-uuid';
import { Link } from 'react-router-dom';

const VacanciesListItem = ({ data }) => {
  const { Title, Location, Locality, Salary, Company_name, Tips } = data;
  return (
    <>
      <Link
        to={`/vacancies/${Location.slice(3)}`}
        className="w-full border rounded-lg bg-white shadow hover:shadow-md transition-shadow duration-300 p-[20px] pb-[10px] cursor-pointer"
      >
        <div className="flex flex-col">
          <div className="flex justify-between">
            <div className="flex-col">
              <h2 className="mb-[10px] font-[500] text-[18px]">{Title}</h2>
              {Salary != 'NULL' && (
                <>
                  <div className="mb-[10px]">
                    <span className="font-[500]">{Salary}</span>
                  </div>
                </>
              )}

              <div className="mb-[10px]">
                <span className="mr-[20px]">{Company_name}</span>
                <span>{Locality}</span>
              </div>
            </div>
          </div>

          {Tips && (
            <>
              <div className="flex flex-wrap">
                {Tips.map((tip) => {
                  return (
                    <Fragment key={uuid()}>
                      <div className="border text-[14px] px-[10px] py-[5px] rounded-[5px] mr-[10px] mb-[10px]">
                        {tip}
                      </div>
                    </Fragment>
                  );
                })}
              </div>
            </>
          )}
        </div>
      </Link>
    </>
  );
};

export default VacanciesListItem;
