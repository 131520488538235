import axios from 'axios';
import { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import PaginationVacancies from './PaginationVacancies';
import SkeletonBlocks from '../SkeletonBlocks';
import VacanciesListItem from './VacanciesListItem';
import uuid from 'react-uuid';

const VacanciesList = () => {
  const [searchParams] = useSearchParams();
  const [vacanciesList, setVacanciesList] = useState(null);
  const [totalVacancies] = useState(36063);
  const limit = 48;

  const page = useMemo(
    () => Number(searchParams.get('page')) || 1,
    [searchParams.get('page')]
  );

  const totalPages = useMemo(() => {
    return Math.ceil(totalVacancies / limit);
  }, [totalVacancies]);

  const getVacancies = async () => {
    if (!(page >= 1 && page <= totalPages)) {
      setVacanciesList(null);
      return;
    }
    await axios
      .get(
        `https://server.a-rogovsky1276.workers.dev/vacancies?page=${page}&limit=${limit}`
      )
      .then((res) => res.data)
      .then((res) => {
        setVacanciesList(res);
      });
  };

  useEffect(() => {
    getVacancies();
  }, [page]);

  return (
    <>
      <div className="p-[40px] max-w-[1150px] w-full mx-auto flex flex-col gap-y-[20px]">
        {!vacanciesList ? (
          <>
            <div className="grid grid-vacancies gap-[15px]">
              <SkeletonBlocks count={48} />
            </div>
          </>
        ) : (
          <>
            {totalVacancies && page == 1 && (
              <>
                <div className="font-[500] text-[21px]">
                  {totalVacancies} vacancies
                </div>
              </>
            )}
            <div className="grid grid-vacancies gap-[15px]">
              {vacanciesList.map((vacancy) => {
                return <VacanciesListItem key={uuid()} data={vacancy} />;
              })}
            </div>
            <PaginationVacancies
              totalPages={totalPages}
              currentPage={page}
              setVacanciesList={setVacanciesList}
            />
          </>
        )}
      </div>
    </>
  );
};

export default VacanciesList;
