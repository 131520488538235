import uuid from 'react-uuid';

const Employment = () => {
  const blocks = [
    'Fill out your resume and send',
    'Processing resumes and searching for company inquiries',
    'Passing the interview in Zoom in English',
    'Receiving your offers in the mail with the conditions',
    'Entering into a contract with an employer',
    'Completing a 100% paid probationary period',
    'Long-term employment',
  ];
  return (
    <>
      <div className="w-full py-[128px]">
        <div className="w-full max-w-[1200px] mx-auto px-[25px]">
          <div className="flex flex-col items-center">
            <h1 className="text-[28px] font-[500] text-center">
              How employment happens
            </h1>
            <ul className="flex flex-col mt-[35px] gap-[20px]">
              {blocks.map((text) => {
                return (
                  <li
                    className="w-[300px] h-[120px] px-[30px] flex items-center text-center border rounded-lg text-[21px] bg-white"
                    key={uuid()}
                  >
                    {text}
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Employment;
