import axios from 'axios';

export const toLowerStr = (string) => {
  return string
    .split('')
    .map((letter) => letter.toLowerCase())
    .join('');
};

export const getCompanies = async () => {
  const response = await axios.get(
    'https://server.a-rogovsky1276.workers.dev/companies'
  );
  const data = await response.data;
  return data;
};

export const formatTime = (timestamp) => {
  const now = new Date();
  const diff = now.getTime() - timestamp;

  const days = Math.floor(diff / (24 * 60 * 60 * 1000));
  const hours = Math.floor((diff % (24 * 60 * 60 * 1000)) / (60 * 60 * 1000));
  return `${days} days ${hours} hours ago`;
};
