import { useEffect, useState } from 'react';
import axios from 'axios';
import uuid from 'react-uuid';
import VacanciesListItem from '../../Vacancy/VacanciesListItem';
import SkeletonBlocks from '../../SkeletonBlocks';
import { Link } from 'react-router-dom';

const VacancyShowcase = () => {
  const [vacanciesList, setVacanciesList] = useState(null);
  const page = 1;
  const limit = 6;

  const getVacancies = async () => {
    await axios
      .get(
        `https://server.a-rogovsky1276.workers.dev/vacancies?page=${page}&limit=${limit}`
      )
      .then((res) => res.data)
      .then((res) => {
        setVacanciesList(res);
      });
  };

  useEffect(() => {
    getVacancies();
  }, []);

  return (
    <>
      <div className="w-full py-[40px] bg-white">
        <div className="w-full max-w-[1200px] mx-auto px-[25px]">
          <div className="flex flex-col">
            <div className="text-center text-[31px] mb-[40px] font-[500]">
              Vacancies
            </div>
            <div className="grid grid-vacancies gap-[15px]">
              {!vacanciesList ? (
                <SkeletonBlocks count={limit} />
              ) : (
                vacanciesList.map((vacancy) => {
                  return <VacanciesListItem key={uuid()} data={vacancy} />;
                })
              )}
            </div>
            <div className="flex justify-end">
              <Link
                className="mt-[20px]"
                to="/vacancies"
                onClick={() => window.scrollTo(0, 0)}
              >
                <button className=" bg-red-500 hover:bg-red-600 transition-all duration-300 py-[10px] px-[15px] font-[500] text-white rounded-[8px] flex gap-[10px]">
                  Browrse all vacancies
                  <i className="material-icons">arrow_right_alt</i>
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VacancyShowcase;
