import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  user: null,
  isLoading: true,
  companies: [],
};

const pageSlice = createSlice({
  name: 'user_slice',
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    addCV: (state, action) => {
      state.user['CV'].push(action.payload);
    },
    changeCV: (state, action) => {
      const index = state.user['CV'].indexOf(
        state.user['CV'].find((cv) => {
          if (cv['Location'] == action.payload['Location']) {
            return cv;
          }
        })
      );
      state.user['CV'][index] = action.payload;
    },
    setCompanies: (state, action) => {
      state.companies = action.payload;
    },
    setTotalVacancies: (state, action) => {
      state.totalVacancies = action.payload;
    },
  },
});

export const pageActions = pageSlice.actions;
export const pageReducer = pageSlice.reducer;
