import { useAuth0 } from '@auth0/auth0-react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const UserHeader = () => {
  const { loginWithRedirect, logout, ...auth0Values } = useAuth0();
  const { user } = useSelector((state) => state.page);
  const [isPopUp, setIsPopUp] = useState(false);

  return (
    <>
      <div className="flex gap-5 items-center">
        <div>
          <Link to="/your-cv">
            <button className="h-[40px] text-[14px] bg-slate-100/75 hover:bg-slate-100/50 pl-4 pr-3 font-medium border rounded flex items-center gap-2">
              {user['CV'] && user['CV'].length >= 1
                ? user['CV'].length
                : 'Create CV'}
              <i className="material-icons text-[18px] ">description</i>
            </button>
          </Link>
        </div>
        <div className="relative">
          <div
            className="w-[35px] h-[35px] rounded-full bg-center bg-cover cursor-pointer"
            style={{ backgroundImage: `url('${auth0Values.user.picture}')` }}
            onClick={() => setIsPopUp(!isPopUp)}
          />
          <div
            className={`absolute bg-white right-[0px] -bottom-[45px] rounded shadow-md text-sm z-50 ${
              isPopUp ? 'visible opacity-1' : 'hidden opacity-0'
            }`}
          >
            <button
              className="font-medium px-[30px] py-[8px] "
              onClick={() => {
                sessionStorage.removeItem('USER_ACCESS_TOKEN');
                logout();
              }}
            >
              Logout
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserHeader;
