import React, { useCallback, useEffect, useState } from 'react';
import WorkExpForm from './WorkExpForm';
import uuid from 'react-uuid';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { pageActions } from '../../slices/pageSlice';
import { useNavigate } from 'react-router-dom';

const CVForm = ({ validUser }) => {
  const { companies } = useSelector((state) => state.page);
  const { user } = useSelector((state) => state.page);
  const { setIsLoading } = pageActions;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [position, setPosition] = useState('');
  const [contactNum, setContactNum] = useState('');
  const [workExp, setWorkExp] = useState([]);

  const deleteWorkExp = useCallback((record) => {
    setWorkExp((prev) => {
      const filteredWorkExp = prev.filter((el) => el != record);
      return filteredWorkExp;
    });
  }, []);

  const createCV = async () => {
    dispatch(setIsLoading(true));
    const token = sessionStorage.getItem('USER_ACCESS_TOKEN');
    let workExpFormated = [];

    if (workExp.length != 0) {
      workExpFormated = workExp.map((exp) => {
        if (exp.company['Location']) {
          return {
            Months: Number(exp.months),
            Company: exp.company,
          };
        } else {
          return {
            Company: {
              Company_name: exp.company,
              Domain: exp.domain,
              Contact_information: exp.contactInfo,
            },
            Months: Number(exp.months),
          };
        }
      });
    }

    const data = JSON.stringify({
      user,
      position,
      contactNum,
      workExp: workExpFormated,
    });

    await axios
      .post('https://server.a-rogovsky1276.workers.dev/add-cv', data, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      })
      .then((res) => res.data)
      .then((res) => {
        navigate('/your-cv');
        validUser();
      });
  };

  const submitHandler = () => {
    createCV();
    setPosition('');
    setContactNum('');
    setWorkExp([]);
  };

  return (
    <>
      <div className="w-full h-full flex justify-center items-start">
        <div className="max-w-[650px] w-[95%]  bg-white rounded-lg border mt-[80px] px-8 py-5">
          <div className="flex justify-center">
            <h1 className="text-[30px]">CV</h1>
          </div>
          <form
            className="mt-[30px]"
            onSubmit={(e) => {
              e.preventDefault();
              submitHandler();
            }}
            autoComplete="off"
          >
            <div className="flex flex-col">
              <label
                htmlFor="position"
                className="mb-1 font-medium text-[#444]"
              >
                Position
              </label>
              <input
                type="text"
                id="position"
                required
                className="border-[#ccc] border px-2.5 py-2 rounded"
                value={position}
                onChange={(e) => {
                  setPosition(e.target.value);
                }}
              />
            </div>
            <div className="flex flex-col mt-[20px]">
              <label
                htmlFor="contact_number"
                className="mb-1 font-medium text-[#444]"
              >
                Contact number
              </label>
              <input
                id="contact_number"
                type="text"
                required
                className="border-[#ccc] border px-2.5 py-2 rounded"
                value={contactNum}
                onChange={(e) => {
                  setContactNum(e.target.value);
                }}
              />
            </div>
            <div className="flex flex-col mt-[20px]">
              <div className="mb-1 font-medium text-[#444]">
                Work experience
              </div>
              {workExp.length != 0 && (
                <div className="flex flex-col gap-2.5 mt-2">
                  {workExp.map((exp) => {
                    return (
                      <WorkExpForm
                        key={exp.id}
                        data={exp}
                        deleteRecord={deleteWorkExp}
                        companiesList={companies}
                      />
                    );
                  })}
                </div>
              )}
              <button
                type="button"
                className="flex items-center gap-1.5 pl-2 pr-3 py-2 border rounded-lg w-fit mt-2.5 font-medium text-[14px] bg-red-600 text-white"
                onClick={() =>
                  setWorkExp((prev) => [
                    ...prev,
                    {
                      id: uuid(),
                      company: '',
                      months: 0,
                      domain: '',
                      contactInfo: '',
                    },
                  ])
                }
              >
                <i className="material-icons text-[18px]">add</i>
                Add entry
              </button>
            </div>
            <div className="flex justify-center">
              <button className="px-[15px] py-[10px] font-medium border shadow rounded">
                Create CV
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default CVForm;
