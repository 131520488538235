import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import UserCV from './UserCV';

const UserCVPage = ({ validUser }) => {
  const { user } = useSelector((state) => state.page);
  return (
    <>
      <div className="flex flex-col">
        <div className="max-w-[800px] w-[95%] mx-auto mt-[30px]">
          <div className="mb-[30px] flex justify-end">
            <Link to="/create-cv">
              <button className="bg-black border-black border h-10 px-3 text-white rounded font-medium text-sm hover:bg-white hover:text-black transition duration-200">
                Add new CV
              </button>
            </Link>
          </div>
          <ul className="flex flex-col gap-5">
            {user['CV'].map((cv) => {
              return (
                <UserCV cv={cv} key={cv['Location']} validUser={validUser} />
              );
            })}
          </ul>
        </div>
      </div>
    </>
  );
};

export default UserCVPage;
