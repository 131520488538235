import { Link } from 'react-router-dom';

const Hero = () => {
  return (
    <>
      <div className="w-full py-[128px] pb-[128px]">
        <div className="w-full max-w-[1200px] mx-auto px-[25px]">
          <div className="flex flex-wrap items-center gap-x-[120px] justify-center">
            <div>
              <h1 className="font-[500] text-[36px] text-center">
                EMPLOYMENT IN IT <br />
                worldwide
              </h1>
              <h3 className="text-center mt-[30px] text-[24px] text-[rgb(0,0,0,0.7)] leading-[36px]">
                Fill out your resume
                <br /> and
                <br /> wait for the best offer
              </h3>
            </div>
            <div>
              <div className="w-[300px] h-[200px] mt-[60px] flex flex-col items-center justify-between bg-white rounded-[30px] border-[1px] px-[30px] py-[20px]">
                <h3 className="text-[21px] font-[500]">Leave a CV</h3>
                <Link to="/cv">
                  <button className="px-[20px] py-[8px] max-w-[215px] font-[500] w-full text-white rounded-lg text-[20px] bg-blue-400">
                    Fill out
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
