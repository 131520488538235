const ChoiseOfCountry = () => {
  return (
    <>
      <div className="w-full py-[90px] bg-white">
        <div className="w-full max-w-[1200px] mx-auto px-[25px]">
          <div className="flex flex-col justify-center items-center ">
            <div className="text-[28px] font-[500] text-center">
              Choice of country of employment
            </div>
            <div className="text-center text-[20px] mt-[30px] text-[rgb(0,0,0,0.7)]">
              We work with employers all over the world
              <br /> so
              <br /> All you have to do is specify what you want
              <br />
              <span className="text-black text-[28px] leading-[70px] font-[500]">
                IN SUMMARY
              </span>
              <br />
              or leave it blank,
              <br /> if you are not interested in this choice
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChoiseOfCountry;
