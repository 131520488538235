import React from 'react';

const TipsForDeal = () => {
  return (
    <>
      <div className="w-full py-[90px] bg-white mt-[60px]">
        <div className="w-full max-w-[1200px] mx-auto px-[25px]">
          <div className="flex flex-col max-w-[550px] w-full mx-auto px-[40px]">
            <div className="text-[28px] font-[500] text-center">
              Our tips for getting the best deal
            </div>
            <ol className="list-decimal text-[18px] mt-[30px] leading-[45px]">
              <li>Describe your skills as fully as possible</li>
              <li>Include accurate information about your qualifications</li>
              <li>Include your LinkedIn profile</li>
              <li>
                Describe any projects you have completed in the past. If you
                have signed a nondisclosure agreement, simply state what has
                been completed.
              </li>
              <li>
                Do not write false information, because you will be blocked
              </li>
            </ol>
          </div>
        </div>
      </div>
    </>
  );
};

export default TipsForDeal;
