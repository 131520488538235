import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toLowerStr } from '../../helpers';
import uuid from 'react-uuid';
import axios from 'axios';

const EditUserExp = ({ expFromCV, setWorkExp, cvLocation }) => {
  const { user } = useSelector((state) => state.page);
  const { companies } = useSelector((state) => state.page);
  const [companiesDropDown, setCompaniesDropDown] = useState([...companies]);

  const [editedExpFromCV] = useState({ ...expFromCV });
  const [company, setCompany] = useState({ ...editedExpFromCV['Company'] });
  const [months, setMonths] = useState(editedExpFromCV['Months']);

  const [isDropDown, setIsDropDown] = useState(false);

  const deleteExp = () => {
    setWorkExp((prev) => {
      return prev.filter(
        (exp) => exp['Location'] != editedExpFromCV['Location']
      );
    });

    if (editedExpFromCV['Location'].substring(0, 3) == 'rec') {
      const token = sessionStorage.getItem('USER_ACCESS_TOKEN');
      const data = JSON.stringify({
        user,
        cvLocation,
        expLocation: editedExpFromCV['Location'],
      });
      console.log(user);
      axios
        .post('https://server.a-rogovsky1276.workers.dev/delete-exp', data, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        })
        .then((res) => res.data)
        .then((res) => {
          console.log(res);
        });
    }
  };

  const valCompInput = (e) => {
    const companyToFind = companies.find(
      (comp) => comp['Company_name'] == e.target.value
    );
    if (companyToFind) {
      setCompany({ ...companyToFind });
      setIsDropDown(false);
    } else {
      setCompany((prev) => {
        const prevCopied = { ...prev };

        if (prev['Cooperate'] == true) {
          delete prevCopied['Location'];
          return {
            ...prevCopied,
            Company_name: e.target.value,
            Cooperate: false,
            Contact_information: '',
            Domain: '',
          };
        } else {
          return {
            ...prevCopied,
            Company_name: e.target.value,
            Cooperate: false,
          };
        }
      });
    }

    if (e.target.value == '') {
      setIsDropDown(false);
      return;
    }
    const toLowerInput = toLowerStr(e.target.value);
    setCompaniesDropDown(() => {
      return companies.filter((company) => {
        const toLowerCompany = toLowerStr(company['Company_name']);
        if (
          toLowerCompany.includes(toLowerInput) &&
          toLowerCompany != toLowerInput
        ) {
          setIsDropDown(true);
          return company;
        }
      });
    });
  };

  useEffect(() => {
    setWorkExp((prev) => {
      const arr = [...prev];
      const toEdit = arr.find(
        (exp) => exp['Location'] == editedExpFromCV['Location']
      );
      toEdit['Company'] = { ...company };
      toEdit['Months'] = Number(months);
      return arr;
    });
  }, [company, months]);

  return (
    <>
      <li
        className={`flex flex-col flex-wrap gap-[20px] px-5 py-5 mt-2 border-[1px] border-black rounded w-fit pr-8 relative`}
      >
        <button
          type="button"
          className="absolute top-1 right-1"
          onClick={() => deleteExp()}
        >
          <i className="material-icons hover:text-red-600">close</i>
        </button>
        <div className="flex gap-5">
          <div className="flex gap-[15px]">
            <div className="font-medium">Company name:</div>
            <div className="relative">
              <input
                type={'text'}
                value={company['Company_name']}
                className="border-[#ccc] border px-2.5 rounded w-fit "
                data-name="input"
                onChange={(e) => {
                  valCompInput(e);
                }}
                onMouseLeave={() => {
                  setIsDropDown(false);
                }}
              />
              {isDropDown && (
                <ul
                  className={`flex flex-col absolute shadow border top-[25px] rounded bg-white w-full z-50`}
                  data-name="drop-down"
                  onMouseEnter={() => {
                    setIsDropDown(true);
                  }}
                  onMouseLeave={() => {
                    setIsDropDown(false);
                  }}
                >
                  {companiesDropDown &&
                    companiesDropDown.map((company) => {
                      return (
                        <li
                          key={uuid()}
                          className="px-2.5 py-1 hover:bg-slate-100 cursor-pointer"
                          onClick={() => {
                            setCompany({ ...company });
                            setIsDropDown(false);
                          }}
                        >
                          {company['Company_name']}
                        </li>
                      );
                    })}
                </ul>
              )}
            </div>
          </div>
          <div className="flex gap-[15px]">
            <div className="font-medium">Experience:</div>
            <input
              type={'number'}
              value={months}
              className="border-[#ccc] border px-2.5 rounded w-fit "
              onChange={(e) => {
                setMonths(e.target.value);
              }}
            />
          </div>
        </div>
        {!companies.find(
          (comp) => comp['Company_name'] == company['Company_name']
        ) && (
          <>
            <div className="flex flex-wrap justify-between border-t border-black pt-5">
              <div className="flex items-center justify-start gap-[15px] ">
                <div className="font-medium">Domain:</div>
                <input
                  id="company_domain"
                  type="text"
                  required
                  className="border-[#ccc] border px-2.5 rounded "
                  value={company['Domain']}
                  onChange={(e) => {
                    setCompany((prev) => {
                      return { ...prev, Domain: e.target.value };
                    });
                  }}
                />
              </div>
              <div className="flex justify-start gap-[15px] items-center">
                <div className="font-medium">Contact info:</div>
                <input
                  id="company_contact"
                  type="text"
                  required
                  className="border-[#ccc] border px-2.5 rounded w-fit "
                  value={company['Contact_information']}
                  onChange={(e) => {
                    setCompany((prev) => {
                      return { ...prev, Contact_information: e.target.value };
                    });
                  }}
                />
              </div>
            </div>
          </>
        )}
      </li>
    </>
  );
};

export default React.memo(EditUserExp);
