import { useNavigate } from 'react-router-dom';

const ThankPage = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="w-full min-h-[calc(100vh-64px)] px-[30px] flex justify-center">
        <div className="max-w-[500px] flex flex-col items-center mt-[120px] text-center">
          <div className="font-[500] text-[31px]">
            Thank you for using our resource!
          </div>
          <div className="mt-[60px] text-[18px]">
            If you have taken into account all of our wishes and described your
            skills, experience and projects in detail, we will try to make you
            some suggestions in the coming days
          </div>
          <button
            className="mt-[30px] py-[10px] px-[20px] bg-red-500 hover:bg-red-600 transition duration-300 font-[500] text-white rounded-lg"
            onClick={() => {
              navigate('/');
            }}
          >
            Return to the main page
          </button>
        </div>
      </div>
    </>
  );
};

export default ThankPage;
