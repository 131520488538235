import { Link } from 'react-router-dom';

const CVOptions = () => {
  return (
    <>
      <div className="w-full px-[30px] min-h-[calc(100vh-64px)] flex flex-col flex-1">
        <div className="w-full flex flex-col flex-1 items-center">
          <div className="py-[60px] text-[28px] font-[500]">You can:</div>
          <div className="flex gap-[30px] justify-center flex-wrap">
            <Link to="/cv/upload">
              <div className="h-[200px] w-[200px] bg-white shadow hover:shadow-md transition-all duration-300 rounded-lg flex justify-center items-center text-[18px] font-[500] cursor-pointer select-none">
                <div>Upload CV</div>
              </div>
            </Link>
            <div className="h-[200px] w-[200px] bg-white shadow  transition-all duration-300 rounded-lg flex justify-center items-center text-[18px] font-[500] cursor-not-allowed bg-[rgb(0,0,0,0.025)] text-[rgb(0,0,0,0.25)] select-none">
              <div>Create CV</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CVOptions;
