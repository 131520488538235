import { useSelector } from "react-redux";

const LoadingPage = () => {
  const { isLoading } = useSelector((state) => state.page);
  return (
    <>
      <div
        className={`h-full fixed top-0 w-full z-50 bg-white transition-all duration-500 ${
          isLoading ? "opacity-1 visible" : "opacity-0 invisible"
        }`}
      >
        <div className="gooey">
          <span className="dot"></span>
          <div className="dots">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoadingPage;
