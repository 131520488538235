import React, { useEffect, useState } from 'react';
import uuid from 'react-uuid';
import { toLowerStr } from '../../helpers';

const WorkExpForm = ({ data, deleteRecord, companiesList }) => {
  const [companiesDropDown, setCompaniesDropDown] = useState([
    ...companiesList,
  ]);
  const [company, setCompany] = useState(data.company);
  const [months, setMonths] = useState(data.months);
  const [domain, setDomain] = useState(data.domain);

  const [contactInfo, setContactInfo] = useState(data.contactInfo);
  const [isDropDown, setIsDropDown] = useState(false);

  const valCompInput = (e) => {
    const companyToFind = companiesList.find(
      (comp) => comp['Company_name'] == e.target.value
    );
    if (companyToFind) {
      setCompany({ ...companyToFind });
      setIsDropDown(false);
    } else {
      setCompany(e.target.value);
    }

    if (e.target.value == '') {
      setIsDropDown(false);
      return;
    }
    const toLowerInput = toLowerStr(e.target.value);
    setCompaniesDropDown(() => {
      return companiesList.filter((company) => {
        const toLowerCompany = toLowerStr(company['Company_name']);
        if (
          toLowerCompany.includes(toLowerInput) &&
          toLowerCompany != toLowerInput
        ) {
          setIsDropDown(true);
          return company;
        }
      });
    });
  };

  useEffect(() => {
    data.company = company;
    data.months = months;
    data.domain = domain;
    data.contactInfo = contactInfo;
  }, [company, months, domain, contactInfo]);

  return (
    <>
      <div className=" border-[rgb(0,0,0,0.1)] border w-full  opacity-1 rounded">
        <div className="relative px-[15px] py-[20px] flex  flex-wrap gap-x-16 gap-y-5">
          <div className="flex flex-col justify-center relative">
            <label
              className="mb-1 font-medium text-[#444]"
              htmlFor="company_name"
            >
              Company name
            </label>
            <input
              id="company_name"
              type="text"
              className="border-[#ccc] border px-2.5 py-1 rounded w-fit"
              value={
                company['Company_name'] ? company['Company_name'] : company
              }
              required
              onChange={(e) => {
                valCompInput(e);
              }}
              onMouseLeave={() => {
                setIsDropDown(false);
              }}
            />
            {isDropDown && (
              <ul
                className={`flex flex-col absolute shadow border top-[60px] rounded bg-white w-full z-50`}
                data-name="drop-down"
                onMouseEnter={() => {
                  setIsDropDown(true);
                }}
                onMouseLeave={() => {
                  setIsDropDown(false);
                }}
              >
                {companiesDropDown &&
                  companiesDropDown.map((company) => {
                    return (
                      <li
                        key={uuid()}
                        className="px-2.5 py-1 hover:bg-slate-100 cursor-pointer"
                        onClick={() => {
                          setCompany({ ...company });
                          setIsDropDown(false);
                        }}
                      >
                        {company['Company_name']}
                      </li>
                    );
                  })}
              </ul>
            )}
          </div>
          <div className="flex flex-col justify-center">
            <label
              className="mb-1 font-medium text-[#444]"
              htmlFor="company_exp"
            >
              Experience (in months)
            </label>
            <input
              id="company_exp"
              type="number"
              required
              className="border-[#ccc] border px-2.5 py-1 rounded w-fit"
              value={months}
              onChange={(e) => {
                setMonths(e.target.value);
              }}
            />
          </div>
          <i
            className="material-icons text-[21px] absolute top-[10px] right-[10px] cursor-pointer transition-all duration-200 hover:text-red-600"
            onClick={() => deleteRecord(data)}
            title="delete record"
          >
            close
          </i>
        </div>
        {!companiesList.find(
          (comp) => comp['Company_name'] == company['Company_name']
        ) && (
          <>
            <div className="flex flex-wrap px-[15px] pb-[10px] gap-x-16 gap-y-5">
              <div className="flex flex-col justify-center">
                <label
                  className="mb-1 font-medium text-[#444]"
                  htmlFor="company_domain"
                >
                  Domain
                </label>
                <input
                  id="company_domain"
                  type="text"
                  required
                  className="border-[#ccc] border px-2.5 py-1 rounded w-fit"
                  value={domain}
                  onChange={(e) => {
                    setDomain(e.target.value);
                  }}
                />
              </div>
              <div className="flex flex-col justify-center">
                <label
                  className="mb-1 font-medium text-[#444]"
                  htmlFor="company_contact"
                >
                  Contact info
                </label>
                <input
                  id="company_contact"
                  type="text"
                  required
                  className="border-[#ccc] border px-2.5 py-1 rounded w-fit"
                  value={contactInfo}
                  onChange={(e) => {
                    setContactInfo(e.target.value);
                  }}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default React.memo(WorkExpForm);
