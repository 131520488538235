import { Link } from 'react-router-dom';

const PaginationVacancies = ({ currentPage, totalPages, setVacanciesList }) => {
  const displayButtons = () => {
    const buttons = [];

    if (currentPage <= 3) {
      for (let i = 1; i <= 4; i++) {
        if (currentPage < 3 && i == 4) continue;
        buttons.push(
          <>
            <Link
              to={`/vacancies?page=${i}`}
              onClick={() => {
                if (currentPage != i) setVacanciesList(null);
              }}
            >
              <button
                className={`px-4 py-2 rounded shadow-sm hover:shadow-none border-[2px] bg-white font-[600] ${
                  currentPage == i && 'text-red-500 border-red-500'
                }`}
              >
                {i}
              </button>
            </Link>
          </>
        );
      }
      buttons.push(
        <>
          <div className="px-2 flex items-end py-1">...</div>
        </>
      );
      buttons.push(
        <>
          <Link
            to={`/vacancies?page=${totalPages}`}
            onClick={() => {
              if (currentPage != totalPages) setVacanciesList(null);
            }}
          >
            <button
              className={`px-4 py-2 rounded shadow-sm hover:shadow-none border-[2px] bg-white font-[600]`}
            >
              {totalPages}
            </button>
          </Link>
        </>
      );
    }

    if (currentPage >= 4 && currentPage <= totalPages - 3) {
      buttons.push(
        <Link
          to={`/vacancies?page=1`}
          onClick={() => {
            if (currentPage != 1) setVacanciesList(null);
          }}
        >
          <button
            className={`px-4 py-2 rounded shadow-sm hover:shadow-none border-[2px] bg-white font-[600]`}
          >
            1
          </button>
        </Link>
      );
      buttons.push(
        <>
          <div className="px-2 flex items-end py-1">...</div>
        </>
      );
      for (let i = currentPage - 1; i <= currentPage + 1; i++) {
        buttons.push(
          <Link
            to={`/vacancies?page=${i}`}
            onClick={() => {
              if (currentPage != i) setVacanciesList(null);
            }}
          >
            <button
              className={`px-4 py-2 rounded shadow-sm hover:shadow-none border-[2px] bg-white font-[600] ${
                currentPage == i && 'text-red-500 border-red-500'
              }`}
            >
              {i}
            </button>
          </Link>
        );
      }
      buttons.push(
        <>
          <div className="px-2 flex items-end py-1">...</div>
        </>
      );
      buttons.push(
        <>
          <Link
            to={`/vacancies?page=${totalPages}`}
            onClick={() => {
              if (currentPage != totalPages) setVacanciesList(null);
            }}
          >
            <button
              className={`px-4 py-2 rounded shadow-sm hover:shadow-none border-[2px] bg-white font-[600] `}
            >
              {totalPages}
            </button>
          </Link>
        </>
      );
      return buttons;
    }

    if (currentPage >= totalPages - 3) {
      buttons.push(
        <>
          <Link
            to={`/vacancies?page=1`}
            onClick={() => {
              if (currentPage != 1) setVacanciesList(null);
            }}
          >
            <button
              className={`px-4 py-2 border rounded shadow-sm hover:shadow-none bg-white font-[600]`}
            >
              1
            </button>
          </Link>
        </>
      );
      buttons.push(
        <>
          <div className="px-2 flex items-end py-1">...</div>
        </>
      );
      for (let i = totalPages - 3; i <= totalPages; i++) {
        if (currentPage >= totalPages - 1 && i == totalPages - 3) continue;
        buttons.push(
          <>
            <Link
              to={`/vacancies?page=${i}`}
              onClick={() => {
                if (currentPage != i) setVacanciesList(null);
              }}
            >
              <button
                className={`px-4 py-2 rounded shadow-sm hover:shadow-none border-[2px] bg-white font-[600] ${
                  currentPage == i && 'text-red-500 border-red-500'
                }`}
              >
                {i}
              </button>
            </Link>
          </>
        );
      }
    }

    return buttons;
  };

  return (
    <>
      <div
        className="flex justify-center gap-2 w-fit mx-auto"
        onClick={() => {
          window.scrollTo(0, 0);
        }}
      >
        {currentPage != 1 && (
          <Link
            to={`/vacancies?page=${currentPage - 1}`}
            onClick={() => {
              setVacanciesList(null);
            }}
          >
            <button
              className={`px-4 py-2 rounded shadow-sm hover:shadow-none border-[2px] bg-white font-[600] h-full`}
            >
              Previous
            </button>
          </Link>
        )}
        {displayButtons()}
        {currentPage != totalPages && (
          <Link
            to={`/vacancies?page=${currentPage + 1}`}
            onClick={() => {
              setVacanciesList(null);
            }}
          >
            <button
              className={`px-4 py-2 rounded shadow-sm hover:shadow-none border-[2px] bg-white font-[600] h-full`}
            >
              Next
            </button>
          </Link>
        )}
      </div>
    </>
  );
};

export default PaginationVacancies;
