import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import VacancyInformation from './VacancyInformation';

const VacancyPage = () => {
  const { id } = useParams();
  const [vacancy, setVacancy] = useState(null);

  const navigate = useNavigate();
  const fetchVacancy = async () => {
    await axios
      .get(`https://server.a-rogovsky1276.workers.dev/vacancies/rec${id}`)
      .then((res) => res.data)
      .then((res) => setVacancy(res.fields));
  };

  useEffect(() => {
    fetchVacancy();
  }, []);
  return (
    <>
      <div className="w-full h-full min-h-[calc(100vh-64px)] px-[10px] py-[30px] flex flex-col">
        <div className="max-w-[850px] w-full mx-auto h-[300px] flex flex-col flex-1 bg-white rounded-lg shadow">
          {!vacancy ? (
            <>
              <div className="gooey">
                <span className="dot"></span>
                <div className="dots">
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="px-[40px] py-[30px] flex items-center  justify-between">
                <button
                  className="flex items-center gap-x-[10px]"
                  onClick={() => navigate(-1)}
                >
                  <span className="flex items-center">
                    <svg
                      className="w-[20px]"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path
                        d="M19 10H1"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                      <path
                        d="M7 4L1 10L7 16"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                    </svg>
                  </span>
                  <span className="font-[600] text-[18px]">Return</span>
                </button>
                <button
                  className="font-[600] text-[16px] rounded-md bg-red-500 text-white px-6 py-1"
                  onClick={() => {
                    navigate('/cv');
                  }}
                >
                  Respond
                </button>
              </div>
              <div className="w-full border-t px-[40px] py-[20px] flex flex-col flex-1">
                <VacancyInformation data={vacancy} />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default VacancyPage;
