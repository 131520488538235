const EmployedInfo = () => {
  return (
    <>
      <div className="w-full py-[90px] bg-white mt-[60px]">
        <div className="w-full max-w-[1200px] mx-auto px-[25px] ">
          <div className="flex flex-col items-center gap-[60px] ">
            <h1 className="text-center text-[28px] leading-[50px] font-[500]">
              More than 17,000 people employed
              <br />
              in IT
              <br />
              for the year 2022
            </h1>
            <h3 className="text-center text-[21px] text-[rgb(0,0,0,0.7)] ">
              Indicate your skills, your expertise
              <br /> and
              <br /> We'll find the right job for you
            </h3>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmployedInfo;
