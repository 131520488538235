const WhatProvide = () => {
  return (
    <>
      <div className="w-full py-[90px] bg-white mt-[90px]">
        <div className="w-full max-w-[1200px] mx-auto px-[25px]">
          <div className="max-w-[650px] mx-auto w-full px-[20px] ">
            <div className="text-center text-[28px] font-[500]">
              Your place of residence/location
            </div>
            <div className="mt-[35px]">
              <div className="text-[20px] font-[500]">
                We provide employment for:
              </div>
              <ol className="list-decimal text-[18px] leading-[35px] mt-[10px]">
                <li className="relative left-[35px]">Remote work in IT</li>
                <li className="relative left-[35px]">
                  Local with binding to the workplace
                </li>
                <li className="relative left-[35px]">
                  Hybrid - combining the first two items
                </li>
              </ol>
            </div>
            <div className="mt-[35px]">
              <div className="text-[20px] font-[500]">
                If you indicate on your resume that you are only interested in
                remote employment, you will not have to travel:
              </div>
              <ul className="list-disc text-[18px] leading-[35px] mt-[10px]">
                <li className="relative left-[30px]">
                  neither for an interview
                </li>
                <li className="relative left-[35px]">or to sign a contract</li>
                <li className="relative left-[35px]">or to the workplace</li>
              </ul>
            </div>
            <div className="font-[500] text-[28px] py-[25px] ">
              You are always where you want to be!
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WhatProvide;
