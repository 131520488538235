import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const CVUpload = () => {
  const [name, setName] = useState('');
  // const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  // const [isDragging, setIsDragging] = useState(false);
  // const [files, setFiles] = useState([]);

  const navigate = useNavigate();

  // const handleDragEnter = (e) => {
  //   e.preventDefault();
  //   setIsDragging(true);
  // };

  // const handleDragOver = (e) => {
  //   e.preventDefault();
  // };

  // const handleDragLeave = (e) => {
  //   e.preventDefault();
  //   setIsDragging(false);
  // };

  // const handleDrop = (e) => {
  //   e.preventDefault();
  //   setIsDragging(false);
  //   const droppedFiles = [...e.dataTransfer.files];
  //   setFiles([...droppedFiles]);
  // };

  const handleFormSubmit = async () => {
    navigate('/thank-page');

    await axios
      .post(
        'https://server.a-rogovsky1276.workers.dev/create-user',
        JSON.stringify({
          Name: name,
          Email: email,
          // Contact_information: phone,
          // CV_imported: [
          //   {
          //     filename: file.name,
          //     content_type: file.type,
          //     size: file.size,
          //     url: `data:${file.type};base64,${fileData}`,
          //   },
          // ],
        })
      )
      .then((res) => res.data)
      .then((res) => console.log(res));
    // const file = files[0];
    // const reader = new FileReader();
    // reader.readAsDataURL(file);

    // reader.onload = async () => {
    //   const fileData = reader.result.split(',')[1];
    //   console.log(reader.result);
    //   console.log(reader.result.length);
    //   await axios
    //     .post(
    //       'https://server.a-rogovsky1276.workers.dev/create-user',
    //       JSON.stringify({
    //         Name: name,
    //         Email: email,
    //         // Contact_information: phone,
    //         // CV_imported: [
    //         //   {
    //         //     filename: file.name,
    //         //     content_type: file.type,
    //         //     size: file.size,
    //         //     url: `data:${file.type};base64,${fileData}`,
    //         //   },
    //         // ],
    //       })
    //     )
    //     .then((res) => res.data)
    //     .then((res) => console.log(res));
    // };
  };

  return (
    <>
      <div className="py-[90px] px-[30px]">
        <form
          className="max-w-[500px] w-full mx-auto rounded-lg border bg-white px-[40px] py-[20px] flex flex-col"
          onSubmit={(e) => {
            e.preventDefault();
            handleFormSubmit();
          }}
        >
          <div className="text-center text-[28px] font-[500] mb-[20px]">
            Upload CV Form
          </div>
          <div className="flex flex-col mt-[20px]">
            <label>Name</label>
            <input
              required
              className="border rounded p-[6px] mt-[5px]"
              type={'text'}
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
          </div>
          <div className="flex flex-col mt-[20px]">
            <label>Email</label>
            <input
              required
              className="border rounded p-[6px] mt-[5px]"
              type={'email'}
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
          </div>
          {/* <div className="flex flex-col mt-[20px]">
            <label>Phone</label>
            <input
              required
              className="border rounded p-[6px] mt-[5px]"
              type={'text'}
              value={phone}
              onChange={(e) => {
                setPhone(e.target.value);
              }}
            />
          </div> */}
          {/* <div
            className={`dropzone ${isDragging ? 'dragging' : ''} my-[20px]`}
            onDragEnter={handleDragEnter}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
          >
            {files.length == 0 && (
              <>
                <div className="border border-[rgb(0,0,0,0.1)] rounded mt-[25px] relative">
                  <div className="absolute w-full h-full flex items-center justify-center">
                    Drop file here
                  </div>
                  <input
                    type={'file'}
                    className="px-[10px] py-[10px] w-full text-center rounded opacity-0"
                    title="Drop file here"
                    onChange={(e) => setFiles([...e.target.files])}
                  />
                </div>
              </>
            )}

            {files.length == 1 && (
              <>
                <div className="pt-[15px] pl-[10px]">{files[0].name}</div>
              </>
            )}
            <div className="flex gap-[10px] mt-[10px]">
              <label
                htmlFor="fileInput"
                className="relative bg-gray-100 px-4 py-2 rounded-md text-gray-700 font-medium shadow-sm hover:bg-gray-200 cursor-pointer"
              >
                <span>Choose a file</span>
                <input
                  required={files.length == 0}
                  id="fileInput"
                  type="file"
                  onChange={(e) => setFiles([...e.target.files])}
                  className="absolute inset-0 w-full h-full opacity-0 cursor-pointer z-50"
                />
              </label>
              {files.length == 1 && (
                <button
                  type="button"
                  className="relative bg-red-100 px-4 py-2 rounded-md text-gray-700 font-medium shadow-sm hover:bg-red-200 cursor-pointer"
                  onClick={() => {
                    setFiles([]);
                  }}
                >
                  Delete file
                </button>
              )}
            </div>
          </div> */}
          <button className="py-[8px] mt-[20px] bg-blue-500 w-fit px-[40px] text-white font-[500] mx-auto rounded">
            Submit
          </button>
        </form>
      </div>
    </>
  );
};

export default CVUpload;
