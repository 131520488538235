import axios from 'axios';
import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch } from 'react-redux';
import { pageActions } from './slices/pageSlice';

import Header from './components/Header';
import Main from './components/Main';
import LoadingPage from './components/Pages/LoadingPage';
import { getCompanies } from './helpers';

const App = () => {
  const { setIsLoading, setUser, setCompanies } = pageActions;
  const [isPageGenerate, setIsPageGenerate] = useState(false);
  const dispatch = useDispatch();
  const { loginWithRedirect, logout, ...auth0Values } = useAuth0();

  const validUser = async () => {
    await axios
      .post(
        'https://server.a-rogovsky1276.workers.dev/user',
        JSON.stringify({
          user: auth0Values.user,
        }),
        {
          headers: {
            Authorization: `Bearer ${auth0Values.user.sub}`,
          },
        }
      )
      .then((res) => res.data)
      .then((res) => {
        console.log(res);
        dispatch(setUser(res));
        sessionStorage.setItem('USER_ACCESS_TOKEN', res['Location']);
      });
    dispatch(setIsLoading(false));
  };

  useEffect(() => {
    sessionStorage.removeItem('USER_ACCESS_TOKEN');
    if (auth0Values.user) {
      validUser();
    }
  }, [auth0Values.user]);

  useEffect(() => {
    (async () => {
      const companies = await getCompanies();
      dispatch(setCompanies(companies));
    })();
  }, []);

  useEffect(() => {
    if (!isPageGenerate) {
      setTimeout(() => {
        setIsPageGenerate(true);
      }, 2000);
    }

    if (isPageGenerate) {
      if (!auth0Values.user) {
        dispatch(setIsLoading(false));
      }
    }
  }, [isPageGenerate]);

  return (
    <>
      <Header />
      <Main validUser={validUser} />
      <LoadingPage />
    </>
  );
};

export default App;
