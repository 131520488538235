const FAQ = () => {
  return (
    <>
      <div className="w-full py-[90px] bg-white mt-[60px]">
        <div className="w-full max-w-[1200px] mx-auto px-[25px]">
          <div className="flex flex-col max-w-[700px] w-full mx-auto px-[30px] ">
            <div className="text-[28px] font-[500] text-center">FAQ</div>
            <ol className="list-decimal text-[18px] mt-[30px] leading-[35px] flex flex-col gap-[15px]">
              <li>
                <div className="font-[500]">Who conducts the interview?</div>
                <div className="p-[15px]">
                  The interview is conducted by the employer or by our HR or the
                  employer's HR. You will be notified in advance if your
                  qualifications meet the prerequisites
                </div>
              </li>
              <li>
                <div className="font-[500]">
                  How long is the probationary period?
                </div>
                <div className="p-[10px]">
                  Probationary period is usually two months in 95% of cases
                </div>
              </li>
              <li>
                <div className="font-[500]">
                  How is the probationary period paid?
                </div>
                <div className="p-[10px]">
                  Probationary period is paid 100% like regular salary. This
                  will also be stated in the contract.
                </div>
              </li>
              <li>
                <div className="font-[500]">
                  Is it possible to see the contract beforehand?
                </div>
                <div className="p-[10px]">
                  No, each employer gives you his/her contract and you can only
                  see the contract after you have had the interview.
                </div>
              </li>
            </ol>
          </div>
        </div>
      </div>
    </>
  );
};

export default FAQ;
