import parse from 'html-react-parser';
import { formatTime } from '../../helpers';

const VacancyInformation = ({ data }) => {
  const { Company_name, Description, Locality, Salary, Title, Creation_time } =
    data;

  return (
    <>
      <h1 className="text-[30px] font-[600]">{Title}</h1>
      {Salary != 'NULL' && (
        <div className="mt-[10px] text-[18px]">{Salary}</div>
      )}

      <div className="mt-[5px] font-[500]">
        {Company_name}
        <br />
        {Locality}
      </div>
      <div className="pt-[25px]">{parse(Description)}</div>
    </>
  );
};

export default VacancyInformation;
